
import Vue from 'vue';
import axios from 'axios';
import AlertComponent from '@/components/AlertComponent.vue';
import goTo from 'vuetify/lib/services/goto';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'LoginView',
  components: {
    AlertComponent,
  },
  data: () => ({
    valid: false,
    url: '',
    hidePassword: true,
    password: '',
    passwordRules: [
      (v: string) => !!v || 'Password is required',
      // (v: string) => (v || '').length < 8 || 'Password should be at least 8 characters',
    ],
    urlRules: [
      (v: string) => !!v || 'Url is required',
    ],
    username: '',
    usernameRules: [
      (v: string) => !!v || 'Username is required',
      (v: string) => /.+@.+/.test(v) || 'Username must be a valid E-mail',
    ],
    errors: [] as object[],
  }),
  props: {
    errorEx: String,
  },
  computed: {
    ...mapGetters([
      'getApiUrl',
    ]),
  },
  methods: {
    testLoginData() {
      // todo: make request to server and get token
      (this.$refs.loginForm as Vue & { validate: () => boolean }).validate();
    },
    async loginSubmit() {
      this.testLoginData();
      this.errors = [] as object[];
      this.$store.commit('updateApiUrl', this.url);

      if (!this.valid) {
        this.errors.push({
          id: this.errors.length,
          alertType: 'error',
          message: 'Please complete the required server settings.',
        });

        // scroll up top to show error message
        goTo(0);
      } else {
        this.$store.commit('setProgressLoader', true);
        await axios
          .post(`${this.url}/api/accounts/login`, {
            email: this.username,
            password: this.password,
          })
          .then((response) => {
            this.$store.commit('setFirstLogin');
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: response.data.account_id,
              apiToken: response.data.token,
            });
            this.$router.push('/dashboard');
          })
          .catch((error) => {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            const errorData = {
              id: this.errors.length,
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = error.response.data.message;
            }

            this.errors.push(errorData);

            // scroll up top to show error message
            goTo(0);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      }
    },
  },
  mounted() {
    this.url = this.getApiUrl;

    // Show error message after authentication has been invalidated.
    if (this.errorEx) {
      this.errors.push({
        id: 1,
        alertType: 'error',
        message: this.errorEx,
      });
    }
  },
});
