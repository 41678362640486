
import Vue from 'vue';
import AlertComponent from '@/components/AlertComponent.vue';
import axios from 'axios';
import goTo from 'vuetify/lib/services/goto';

export default Vue.extend({
  name: 'FloorTabComponent',
  components: {
    AlertComponent,
  },
  props: [],
  data: () => ({
    valid: false,
    name: '',
    nameRules: [
      (v: string) => !!v || 'Floor Name is required',
    ],
    houseId: null,
    houseIdRules: [
      (v: number) => !!v || 'House is required',
    ],
    houses: [] as object[],
    alertData: [] as object[],
  }),
  created() {
    this.loadHouses();
  },
  mounted() {
    this.$root.$on('floorTabUpdateCollection', (toggle: boolean) => {
      if (toggle) {
        this.formEl?.reset();
        this.houses = [] as object[];
        this.loadHouses();
      }
    });
  },
  computed: {
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean
      });
    },
  },
  methods: {
    testSubmitData() {
      this.formEl.validate();
    },
    async loadHouses() {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/homes`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
            // Accept: 'application/ld+json',
          },
          params: {
            account_id: this.$store.getters.getAccountId,
            'order[id]': 'DESC',
          },
          responseType: 'json',
        })
        .then((response) => {
          // console.log(response.data['hydra:member']);
          response.data.forEach((value: { id: number, name: string }) => {
            this.houses.push({
              text: value.name,
              value: value.id,
            });
          });

          // auto select a house if there is only one selection
          if (this.houses.length === 1) {
            const singleHouseData = JSON.parse(JSON.stringify(this.houses[0]));
            this.houseId = singleHouseData.value;
          }
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          this.alertData.push(errorData);
        });
    },
    async submitButton() {
      this.testSubmitData();
      this.alertData = [] as object[];

      if (this.valid) {
        this.$store.commit('setProgressLoader', true);
        await axios
          .post(`${this.$store.getters.getApiUrl}/api/floors`, {
            name: this.name,
            homeId: `/api/homes/${this.houseId}`,
          }, { headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken } })
          .then((response) => {
            if (response.status !== 201) {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'error',
                message: 'There is something wrong while saving. Please contact an administrator.',
              });
            } else {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'success',
                message: 'Successfully added a new floor',
              });
              this.formEl.reset();

              // Update tab forms collection
              this.$root.$emit('roomTabUpdateCollection', true);
            }
          })
          .catch((error) => {
            /** Authentication checker
             * @TODO optimize this code, make a base interface for this codes aside from validating
             * each page route changed in router/index.ts
             * */
            if (error.response.data.message === 'Invalid credentials.'
              || error.response.status === 401) {
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: null,
                apiToken: null,
              });
              this.$router.push({
                name: 'login',
                params: {
                  errorEx: 'Invalid authentication. Please login.',
                },
              });
            }

            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = error.response.data.violations.shift().message;
            }

            this.alertData.push(errorData);

            // scroll up top to show error message
            goTo(0);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      }
    }
    ,
  },
});
