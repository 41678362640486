
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'App',
  data: () => ({
    alert: true,
    value: 'home',
    appName: process.env.VUE_APP_NAME,
    appVersion: process.env.VUE_APP_VERSION,
    bottomNavigation: [
      {
        value: 'home',
        title: 'Home',
        route: 'dashboard',
        icon: 'mdi-home',
      },
      {
        value: 'search',
        title: 'Search',
        route: 'search',
        icon: 'mdi-magnify',
      },
      {
        value: 'addItem',
        title: 'Add Item',
        route: 'add-item',
        color: 'green',
        icon: 'mdi-plus-thick',
      },
      {
        value: 'invite-housemates',
        title: 'Invite',
        route: 'invite-housemates',
        icon: 'mdi-account-plus',
      },
      {
        value: 'settings',
        title: 'Settings',
        route: 'settings',
        icon: 'mdi-cog',
      },
    ],
  }),
  computed: {
    ...mapGetters([
      'isLoading',
      'isBackButtonEnable',
      'appBarExtraData',
      'appBarExtraAction',
    ]),
  },
  methods: {
    pushPage(pageName: string) {
      if (this.$router.currentRoute.name !== pageName) {
        this.$router.push({ name: pageName });
      }
    },
    appGoBack() {
      this.$router.back();
    },
    appBarExtraFabEvent() {
      this.$root.$emit('appBarExtraFabEvent', true, this.appBarExtraAction);
    },
    //   ...mapActions([
    //     'fetchApiUrl',
    //     'fetchApiToken',
    //   ]),
  },
  // created() {
  //   this.$store.dispatch('fetchApiUrl');
  //   this.$store.dispatch('fetchApiToken');
  // },
});
