
import Vue from 'vue';
import axios from 'axios';
// import AlertComponent from '@/components/AlertComponent.vue';
// import goTo from 'vuetify/lib/services/goto';

export default Vue.extend({
  name: 'HousematesSettingView',
  components: {
    // AlertComponent,
  },
  data: () => ({
    alertData: [] as object[],
    snackbar: {
      enable: false,
      timeout: 2000,
      text: '',
      color: 'white',
      light: true,
      dark: false,
    },
    houses: [] as object[],
    searchText: '',
  }),
  watch: {
    //
  },
  computed: {
    filteredItems() {
      return this.houses.filter((item) => {
        if (!this.searchText) return this.houses;
        const filteredItem = JSON.parse(JSON.stringify(item));
        return filteredItem.houseName.toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
    },
  },
  methods: {
    clearSearch() {
      this.searchText = '';
    },
    setSnackbar(message: string, type: string, timeout = 2000) {
      this.snackbar.enable = true;
      this.snackbar.text = message;
      this.snackbar.timeout = timeout;

      if (type === 'error') {
        this.snackbar.color = 'red';
        this.snackbar.dark = true;
        this.snackbar.light = false;
      } else if (type === 'success') {
        this.snackbar.color = 'green';
        this.snackbar.dark = true;
        this.snackbar.light = false;
      } else if (type === 'info') {
        this.snackbar.color = 'white';
        this.snackbar.dark = false;
        this.snackbar.light = true;
      }
    },
    async loadHousemates() {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/homes/total-housemates/owned/by/${this.$store.getters.getAccountId}`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            //
          },
          responseType: 'json',
        })
        .then((response) => {
          response.data.forEach((value: {
            // eslint-disable-next-line camelcase
            h_id: number,
            // eslint-disable-next-line camelcase
            h_name: string,
            // eslint-disable-next-line camelcase
            total_housemates: number,
          }) => {
            this.houses.push({
              houseId: value.h_id,
              houseName: value.h_name,
              totalHousemates: value.total_housemates,
            });
          });
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          // this.alertData.push(errorData);
          this.setSnackbar(errorData.message, errorData.alertType);
        });
    },
  },
  created() {
    this.loadHousemates();
  },
  mounted() {
    this.$store.commit('setBackButtonEnable', true);
    this.$store.commit('setAppBarExtraData', {
      enable: true,
      pageTitle: 'Housemates',
      fabButton: {
        enable: false,
      },
    });
  },
  destroyed() {
    this.$store.commit('setBackButtonEnable', false);
    this.$store.commit('setAppBarExtraData', {
      enable: false,
      fabButton: {},
    });
  },
});
