
import Vue from 'vue';
import AlertComponent from '@/components/AlertComponent.vue';
import axios from 'axios';
import goTo from 'vuetify/lib/services/goto';

export default Vue.extend({
  name: 'ShelfTabComponent',
  components: {
    AlertComponent,
  },
  props: [],
  data: () => ({
    valid: false,
    name: '',
    nameRules: [
      (v: string) => !!v || 'Shelf Name is required',
    ],
    houseId: null,
    houseIdRules: [
      (v: number) => !!v || 'House is required',
    ],
    houses: [] as object[],
    floorDisabled: true,
    floorId: null,
    floorIdRules: [
      (v: number) => !!v || 'Floor is required',
    ],
    floors: [] as object[],
    roomDisabled: true,
    roomId: null,
    roomIdRules: [
      (v: number) => !!v || 'Room is required',
    ],
    rooms: [] as object[],
    alertData: [] as object[],
  }),
  created() {
    this.loadHouses();
  },
  mounted() {
    this.$root.$on('shelfTabUpdateCollection', (toggle: boolean) => {
      if (toggle) {
        this.formEl.reset();
        this.floorDisabled = true;
        this.roomDisabled = true;
        this.houses = [] as object[];
        this.loadHouses();
      }
    });
  },
  computed: {
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean
      });
    },
  },
  watch: {
    houseId() {
      if (this.houseId) {
        this.floorDisabled = false;
        this.floors = [] as object[];
        this.loadFloors(this.houseId);
      }
    },
    floorId() {
      if (this.floorId) {
        this.roomDisabled = false;
        this.rooms = [] as object[];
        this.loadRooms(this.floorId);
      }
    },
  },
  methods: {
    testSubmitData() {
      this.formEl.validate();
    },
    async loadHouses() {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/homes`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            account_id: this.$store.getters.getAccountId,
            'order[id]': 'DESC',
          },
          responseType: 'json',
        })
        .then((response) => {
          response.data.forEach((value: { id: number, name: string, floors: [] }) => {
            this.houses.push({
              text: `${value.name} (${value.floors.length} floors)`,
              value: value.id,
            });
          });

          // auto select a house if there is only one selection
          if (this.houses.length === 1) {
            const singleHouseData = JSON.parse(JSON.stringify(this.houses[0]));
            this.houseId = singleHouseData.value;
          }
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          this.alertData.push(errorData);
        });
    },
    async loadFloors(houseId: number) {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/floors`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            home_id: houseId,
            'order[id]': 'DESC',
          },
          responseType: 'json',
        })
        .then((response) => {
          response.data.forEach((value: {
            id: number,
            name: string,
            rooms: []
          }) => {
            this.floors.push({
              text: `${value.name} (${value.rooms.length} rooms)`,
              value: value.id,
            });
          });

          // auto select a floor if there is only one selection
          if (this.floors.length === 1) {
            const singleFloorData = JSON.parse(JSON.stringify(this.floors[0]));
            this.floorId = singleFloorData.value;
          }
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          this.alertData.push(errorData);
        });
    },
    async loadRooms(floorId: number) {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/rooms`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            floor_id: floorId,
            'order[id]': 'DESC',
          },
          responseType: 'json',
        })
        .then((response) => {
          response.data.forEach((value: {
            id: number,
            name: string,
          }) => {
            this.rooms.push({
              text: value.name,
              value: value.id,
            });
          });

          // auto select a room if there is only one selection
          if (this.rooms.length === 1) {
            const singleRoomData = JSON.parse(JSON.stringify(this.rooms[0]));
            this.roomId = singleRoomData.value;
          }
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          this.alertData.push(errorData);
        });
    },
    async submitButton() {
      this.testSubmitData();
      this.alertData = [] as object[];

      if (this.valid) {
        this.$store.commit('setProgressLoader', true);
        await axios
          .post(`${this.$store.getters.getApiUrl}/api/cupboards`, {
            name: this.name,
            roomId: `/api/rooms/${this.roomId}`,
          }, { headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken } })
          .then((response) => {
            if (response.status !== 201) {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'error',
                message: 'There is something wrong while saving. Please contact an administrator.',
              });
            } else {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'success',
                message: 'Successfully added a new shelf',
              });
              this.formEl.reset();

              // Update room floors collection
              this.$root.$emit('boxTabUpdateCollection', true);
            }
          })
          .catch((error) => {
            /** Authentication checker
             * @TODO optimize this code, make a base interface for this codes aside from validating
             * each page route changed in router/index.ts
             * */
            if (error.response.data.message === 'Invalid credentials.'
              || error.response.status === 401) {
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: null,
                apiToken: null,
              });
              this.$router.push({
                name: 'login',
                params: {
                  errorEx: 'Invalid authentication. Please login.',
                },
              });
            }

            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = error.response.data.violations.shift().message;
            }

            this.alertData.push(errorData);

            // scroll up top to show error message
            goTo(0);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      }
    },
  },
});
