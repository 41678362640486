import { render, staticRenderFns } from "./FloorTabComponent.vue?vue&type=template&id=00544fa6&scoped=true&"
import script from "./FloorTabComponent.vue?vue&type=script&lang=ts&"
export * from "./FloorTabComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00544fa6",
  null
  
)

export default component.exports