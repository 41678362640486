
import Vue from 'vue';
// import AlertComponent from '@/components/AlertComponent.vue';

export default Vue.extend({
  name: 'SettingsView',
  components: {
    // AlertComponent,
  },
  data: () => ({
    items: {
      general: [
        {
          text: 'Account',
          icon: 'mdi-account',
          description: 'Change your account details',
          method: 'account',
        },
        {
          text: 'Logout',
          icon: 'mdi-logout-variant',
          description: '',
          method: 'logout',
        },
      ],
      management: [
        {
          text: 'Treeview',
          icon: 'mdi-file-tree',
          description: 'See a tree view of your organizer',
          method: 'tree_view',
        },
        {
          text: 'Housemates',
          icon: 'mdi-account-group',
          description: 'Manage your housemates',
          method: 'housemates',
        },
        {
          text: 'Metas',
          icon: 'mdi-list-box-outline',
          description: 'Manage your additional metas',
          method: 'metas',
        },
      ],
    },
    showDialog: false,
  }),
  methods: {
    callback(method: string) {
      if (method === 'logout') {
        this.showDialog = true;
      } else if (method === 'account') {
        this.$router.push({ name: 'settings-account' });
      } else if (method === 'metas') {
        this.$router.push({ name: 'settings-metas' });
      } else if (method === 'housemates') {
        this.$router.push({ name: 'settings-housemates' });
      } else if (method === 'tree_view') {
        this.$router.push({ name: 'settings-tree-view' });
      }
    },
    logout() {
      this.$store.commit('updateAuthenticatedAccount', {
        accountId: null,
        apiToken: null,
      });
      this.$router.push({ name: 'login' });
    },
  },
  mounted() {
    // this.$root.$on('dialogOkEvent', (toggle: boolean) => {
    //   if (toggle) this.logout();
    // });
    //
    // this.$root.$on('dialogCancelEvent', (toggle: boolean) => {
    //   if (toggle) this.showDialog = false;
    // });
  },
});
