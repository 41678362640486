
import Vue from 'vue';
import AlertComponent from '@/components/AlertComponent.vue';
import RoomTabComponent from '@/components/tabs/RoomTabComponent.vue';
import FloorTabComponent from '@/components/tabs/FloorTabComponent.vue';
import BoxTabComponent from '@/components/tabs/BoxTabComponent.vue';
import HouseTabComponent from '@/components/tabs/HouseTabComponent.vue';
import ShelfTabComponent from '@/components/tabs/ShelfTabComponent.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'DashboardView',
  components: {
    AlertComponent,
    HouseTabComponent,
    FloorTabComponent,
    RoomTabComponent,
    ShelfTabComponent,
    BoxTabComponent,
  },
  data: () => ({
    tab: null,
    tabItems: ['House', 'Floor', 'Room', 'Shelf', 'Box'],
  }),
  computed: {
    ...mapGetters([
      'isFirstLogin',
    ]),
  },
  methods: {
    setIsFirstLoginFalseAfterLogin() {
      this.$store.commit('setFirstLogin', false);
    },
    updateTabForm(tabIndex: number) {
      this.$root.$emit(`${this.tabItems[tabIndex].toLowerCase()}TabUpdateCollection`, true);
    },
  },
  mounted() {
    setTimeout(() => this.setIsFirstLoginFalseAfterLogin(), 5000);
  },
});
