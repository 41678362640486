
import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  name: 'SearchView',
  components: {
    // AlertComponent,
  },
  data: () => ({
    // alertData: [] as object[],
    snackbar: {
      enable: false,
      timeout: 2000,
      text: '',
      color: 'white',
      light: true,
      dark: false,
    },
    isActive: false,
    items: [] as object[],
    searchText: '',
  }),
  watch: {
    searchText() {
      if (this.searchText.length > 0) {
        this.searchItems();
      } else {
        this.items = [] as object[];
      }
    },
  },
  computed: {
    // filteredItems() {
    //   // this.searchItems();
    //   // return this.items;
    //   return this.items.filter((item) => {
    //     if (!this.searchText) return this.items;
    //     const filteredItem = JSON.parse(JSON.stringify(item));
    //     return filteredItem.text.toLowerCase()
    //       .includes(this.searchText.toLowerCase());
    //   });
    // },
  },
  methods: {
    goToItemDetails(data: {
      text: string,
      value: string,
      additionalInfo: {
        amount: string,
        description: string,
        itemImageThumbnailUrl: string,
        itemImageUrl: string,
        boxNumber: string,
        boxImageThumbnailUrl: string,
        boxImageUrl: string,
        shelfName: string,
        roomName: string,
        floorName: string,
        houseName: string,
        metas: [],
      },
    }) {
      this.$router.push({
        name: 'detailed-item-view',
        params: {
          text: data.text,
          value: data.value,
          amount: data.additionalInfo.amount,
          description: data.additionalInfo.description,
          itemImageThumbnailUrl: data.additionalInfo.itemImageThumbnailUrl,
          itemImageUrl: data.additionalInfo.itemImageUrl,
          boxNumber: data.additionalInfo.boxNumber,
          boxImageThumbnailUrl: data.additionalInfo.boxImageThumbnailUrl,
          boxImageUrl: data.additionalInfo.boxImageUrl,
          shelfName: data.additionalInfo.shelfName,
          roomName: data.additionalInfo.roomName,
          floorName: data.additionalInfo.floorName,
          houseName: data.additionalInfo.houseName,
          metas: data.additionalInfo.metas ? JSON.stringify(data.additionalInfo.metas) : '',
        },
      });
    },
    clearSearch() {
      this.searchText = '';
    },
    setSnackbar(message: string, type: string, timeout = 2000) {
      this.snackbar.enable = true;
      this.snackbar.text = message;
      this.snackbar.timeout = timeout;

      if (type === 'error') {
        this.snackbar.color = 'red';
        this.snackbar.dark = true;
        this.snackbar.light = false;
      } else if (type === 'success') {
        this.snackbar.color = 'green';
        this.snackbar.dark = true;
        this.snackbar.light = false;
      } else if (type === 'info') {
        this.snackbar.color = 'white';
        this.snackbar.dark = false;
        this.snackbar.light = true;
      }
    },
    async searchItems() {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/items/get/all`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            keyword: this.searchText,
            account_id: this.$store.getters.getAccountId,
            'order[name]': 'ASC',
          },
          responseType: 'json',
        })
        .then((response) => {
          this.items = [] as object[];
          response.data.forEach((value: {
            id: number,
            name: string,
            amount: number,
            description: string,
            itemMetas: [],
            // eslint-disable-next-line camelcase
            image_id: {
              // eslint-disable-next-line camelcase
              file_extension: string,
              // eslint-disable-next-line camelcase
              is_landscape: boolean,
              uuid: string,
            },
            // eslint-disable-next-line camelcase
            box_id: {
              number: number,
              // eslint-disable-next-line camelcase
              image_id: {
                // eslint-disable-next-line camelcase
                file_extension: string,
                // eslint-disable-next-line camelcase
                is_landscape: boolean,
                uuid: string,
              },
              // eslint-disable-next-line camelcase
              cupboard_id: {
                name: string,
                // eslint-disable-next-line camelcase
                room_id: {
                  name: string,
                  // eslint-disable-next-line camelcase
                  floor_id: {
                    name: string,
                    // eslint-disable-next-line camelcase
                    home_id: {
                      name: string,
                    },
                  },
                },
              },
            },
          }) => {
            this.items.push({
              text: value.name,
              value: value.id,
              additionalInfo: {
                amount: value.amount,
                description: value.description,
                itemImageThumbnailUrl: value.image_id ? `${this.$store.getters.getApiUrl}/uploads/thumbnails/thumbnail_${value.image_id.uuid}.${value.image_id.file_extension}` : null,
                itemImageUrl: value.image_id ? `${this.$store.getters.getApiUrl}/uploads/images/${value.image_id.uuid}.${value.image_id.file_extension}` : null,
                boxNumber: value.box_id.number,
                boxImageThumbnailUrl: value.box_id.image_id ? `${this.$store.getters.getApiUrl}/uploads/thumbnails/thumbnail_${value.box_id.image_id.uuid}.${value.box_id.image_id.file_extension}` : null,
                boxImageUrl: value.box_id.image_id ? `${this.$store.getters.getApiUrl}/uploads/images/${value.box_id.image_id.uuid}.${value.box_id.image_id.file_extension}` : null,
                shelfName: value.box_id.cupboard_id.name,
                roomName: value.box_id.cupboard_id.room_id.name,
                floorName: value.box_id.cupboard_id.room_id.floor_id.name,
                houseName: value.box_id.cupboard_id.room_id.floor_id.home_id.name,
                metas: value.itemMetas,
              },
            });
          });
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          // this.alertData.push(errorData);
          this.setSnackbar(errorData.message, errorData.alertType);
        });
    },
    async loadItems() {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/items/get/all`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            account_id: this.$store.getters.getAccountId,
            'order[name]': 'ASC',
          },
          responseType: 'json',
        })
        .then((response) => {
          response.data.forEach((value: {
            id: number,
            name: string,
            amount: number,
            description: string,
            itemMetas: [],
            // eslint-disable-next-line camelcase
            image_id: {
              // eslint-disable-next-line camelcase
              file_extension: string,
              // eslint-disable-next-line camelcase
              is_landscape: boolean,
              uuid: string,
            },
            // eslint-disable-next-line camelcase
            box_id: {
              number: number,
              // eslint-disable-next-line camelcase
              image_id: {
                // eslint-disable-next-line camelcase
                file_extension: string,
                // eslint-disable-next-line camelcase
                is_landscape: boolean,
                uuid: string,
              },
              // eslint-disable-next-line camelcase
              cupboard_id: {
                name: string,
                // eslint-disable-next-line camelcase
                room_id: {
                  name: string,
                  // eslint-disable-next-line camelcase
                  floor_id: {
                    name: string,
                    // eslint-disable-next-line camelcase
                    home_id: {
                      name: string,
                    },
                  },
                },
              },
            },
          }) => {
            this.items.push({
              text: value.name,
              value: value.id,
              additionalInfo: {
                amount: value.amount,
                description: value.description,
                itemImageThumbnailUrl: value.image_id ? `${this.$store.getters.getApiUrl}/uploads/thumbnails/thumbnail_${value.image_id.uuid}.${value.image_id.file_extension}` : null,
                itemImageUrl: value.image_id ? `${this.$store.getters.getApiUrl}/uploads/images/${value.image_id.uuid}.${value.image_id.file_extension}` : null,
                boxNumber: value.box_id.number,
                boxImageThumbnailUrl: value.box_id.image_id ? `${this.$store.getters.getApiUrl}/uploads/thumbnails/thumbnail_${value.box_id.image_id.uuid}.${value.box_id.image_id.file_extension}` : null,
                boxImageUrl: value.box_id.image_id ? `${this.$store.getters.getApiUrl}/uploads/images/${value.box_id.image_id.uuid}.${value.box_id.image_id.file_extension}` : null,
                shelfName: value.box_id.cupboard_id.name,
                roomName: value.box_id.cupboard_id.room_id.name,
                floorName: value.box_id.cupboard_id.room_id.floor_id.name,
                houseName: value.box_id.cupboard_id.room_id.floor_id.home_id.name,
                metas: value.itemMetas,
              },
            });
          });
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          // this.alertData.push(errorData);
          this.setSnackbar(errorData.message, errorData.alertType);
        });
    },
  },
  created() {
    this.loadItems();
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
});
