
import Vue from 'vue';
import AlertComponent from '@/components/AlertComponent.vue';
import axios from 'axios';
import goTo from 'vuetify/lib/services/goto';

export default Vue.extend({
  name: 'HouseTabComponent',
  components: {
    AlertComponent,
  },
  props: [],
  data: () => ({
    valid: false,
    name: '',
    nameRules: [
      (v: string) => !!v || 'House Name is required',
    ],
    alertData: [] as object[],
  }),
  mounted() {
    this.$root.$on('houseTabClearForm', (toggle: boolean) => {
      if (toggle) this.formEl?.reset();
    });
  },
  computed: {
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean
      });
    },
  },
  methods: {
    testSubmitData() {
      this.formEl.validate();
    },
    async submitButton() {
      this.testSubmitData();
      this.alertData = [] as object[];

      if (this.valid) {
        this.$store.commit('setProgressLoader', true);
        await axios
          .post(`${this.$store.getters.getApiUrl}/api/homes`, {
            name: this.name,
            accountId: `/api/accounts/${this.$store.getters.getAccountId}`,
          }, { headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken } })
          .then((response) => {
            if (response.status !== 201) {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'error',
                message: 'There is something wrong while saving. Please contact an administrator.',
              });
            } else {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'success',
                message: 'Successfully added a new house',
              });
              this.formEl.reset();

              // Update tab forms collection
              this.$root.$emit('floorTabUpdateCollection', true);
              this.$root.$emit('roomTabUpdateCollection', true);
              this.$root.$emit('shelfTabUpdateCollection', true);
              this.$root.$emit('boxTabUpdateCollection', true);
            }
          })
          .catch((error) => {
            /** Authentication checker
             * @TODO optimize this code, make a base interface for this codes aside from validating
             * each page route changed in router/index.ts
             * */
            if (error.response.data.message === 'Invalid credentials.'
              || error.response.status === 401) {
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: null,
                apiToken: null,
              });
              this.$router.push({
                name: 'login',
                params: {
                  errorEx: 'Invalid authentication. Please login.',
                },
              });
            }

            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = error.response.data.violations.shift().message;
            }

            this.alertData.push(errorData);

            // scroll up top to show error message
            goTo(0);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      }
    },
  },
});
